import { Suspense } from "react";
import { ToastContainer } from "react-toastify";
import { observer } from "mobx-react";
import { CssBaseline } from "@mui/material";

import { log } from "@core/logger";

import { RouteContent } from "./components/app/RouteContent";
import { ServiceWorker } from "./components/app/ServiceWorker";
import InteractionDialog from "./stores/Interaction/InteractionDialog.component";

import "./app.css";
import "react-toastify/dist/ReactToastify.css";

export const App = observer(function AppRoot() {
  return (
    <>
      <ServiceWorker />
      <CssBaseline />
      <Suspense fallback="loading">
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          limit={2}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <InteractionDialog />
        <RouteContent />
      </Suspense>
    </>
  );
});

log(
  [
    `PLATFORM APP:${import.meta.env.MODE}`,
    `[ v${process.env?.APP_VERSION} ]`,
    `${process.env?.BUILD_DATE}`,
  ].join(" ")
);
log("Feature Flags: ", import.meta.env.REACT_APP_FEATURES);
